/**
 * @file
 * Styles for overall site layout and grids.
*/

@import "variables";

// Set z-indexes

#header, #main {
  position: relative;
}

#header {
  z-index: 2;
}

#main {
  z-index: 0;
}

// 
// PAGE ABOVE 
//

#page-top {
  //max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  display: inline-block;
  float: left;
  height: 42px;
  position: relative;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  background: url(../../images/flags-background.png) top center repeat-x;
  #block-block-1 { // Header Contact Info
    position: relative;
    right: 10px;
    width: initial;
    display: inline-block;
    
    @media only screen and (max-width: 767px) {
      
    }
  
  }
}


//
// Header
//

#header {
  margin-bottom: -6px;
  float: left;
  width: 100%;
  
  display: inline-block;
  
  @media only screen and (max-width: 767px) {
    
  }
  
  #header-inner {
  
    margin: 0 auto;
  
    @media only screen and (max-width: 767px) {
      
    }
  }
  .pagewidth {
    width: 100%;
    margin: 0 auto;
    @include zen-grid-container;
    overflow: visible;
    z-index: 2;
    padding: 0;
    @media only screen and (max-width: 767px) {
    }
  }
}

body.page-home #header #header-inner {
  max-width: 1400px;

  @media only screen and (max-width: 767px) {
  }
}

#header_left { // Header Left (Region)
  padding-left: 2%;
  float: left;
  @include zen-grid-item(8, 1);

  @media only screen and (max-width: 959px) {
    float: left;
    @include zen-grid-item(12, 1);
    padding-left: 2%;
  }
  @media only screen and (max-width: 767px) {
    @include zen-grid-item(24,1);
  }
}

#header_right { // Header Right (Region)
  padding-right: 2%;
  float: right;
  @include zen-grid-item(12, 13);
  
  @media only screen and (max-width: 767px) {
    @include zen-grid-item(24,1);
    clear: both;
  }
  
  #block-menu-block-2 { // Cart and Misc. Links Menu
    float: right;
    @media only screen and (min-width: 768px) and (max-width: 959px) {
      clear: both;
    }
    ul {
      list-style: none;
      li { 
        display: inline; 
      }
    }
  }
}

body.not-front #header_right {
  @media only screen and (min-width: 1900px) {
    padding-right: 140px;
  }
}

#navbar { // Navigation
  clear: both;
  position: absolute;
  right: 0;
  bottom: 0;
  width: initial;
  border-top: none;
  float: none;

  @media only screen and (max-width: 767px) {
    position: relative;
    border-top: none;
  }
  
  @media only screen and (max-width: 1104px) {
    width: 100%; 
    position: static;
    border-top: 2px solid $orange;
  }
  
  ul.menu {
    @media only screen and (max-width: 1104px) {
      padding-left: 0;
    }
  }
  #block-search-form {
    position: absolute;
    left: 0;
    top: -40px;
    @media only screen and (max-width: 1104px) {
      top: -46px;
      right: 90px;
      left: inherit;
    }
    @media only screen and (max-width: 767px) {
      display: none; 
    }
  }
}

body.not-front #navbar {
  padding: 0 20px 0 0;
  @media only screen and (max-width: 1104px) {
    padding: 0;
  }
  @media only screen and (min-width: 1900px) {
    padding: 0 120px 0 0;
    right: 10px;
  }
}

body.front #navbar {
  ul.menu {
    @media only screen and (max-width: 1104px) {
      padding-left: 90px;
    }
  }
}

.not-front #navbar {
  #block-search-form {
    right: 10px;
  }
}

#block-menu-block-1 { // Main Menu Block
  display: block;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

//
// Content Above
//

#content_above {
  position: absolute;
  margin-left: 240px;
  top: 9px;
  z-index: 1;
}

//
// Main Content
//

#block-system-main {
  margin-right: 20px;
  @media only screen and (max-width: 767px) {
    margin-right: 0;
  }
}

body.front #block-system-main {
  margin-right: 0;
}

#main {
  clear: left;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    clear: none;
    //max-width: 1400px;
    //margin: 0 auto;
  }
  .pagewidth {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    @include zen-grid-container();
  }
}

body.front #main {
  //max-width: 1400px;
}

// Filter Region (For Geography & Theme - or other blocks)

#filter-region {
  width: 240px;
  left: -240px;
  margin-left: 240px;
  float: left;
  padding: 10px 0 20px;
  position: relative;
  margin-right: -100%;
  margin-left: 240px;
  
  @include transition(
    left 0.2s ease-in-out,
    top 0.2s ease-in-out
  );
  
}

//
// Sidebar
//

body.front #sidebar {
  display: none;
}

// Sidebar Grid, Make Sidebar background match width of sidebar
#sidebar, #sidebar-bg {
  width: 240px;
  float: left;
  margin-right: -100%;
  margin-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  @include transition (
    margin-left 0.2s ease-in-out
  );
  @media only screen and (min-width: 768px) {
    //@include zen-grid-item(8,1);
    padding: 0;
  }
  @media only screen and (min-width: 1104px) {
    //@include zen-grid-item(6,1);
  }
  @media only screen and (min-width: 1615px) {
    //@include zen-grid-item(4,1);
  }
  @media only screen and (max-width: 767px) {
  }
  &.with-filters {
    margin-left: -250px;
  }
}

// Show Filters when user arrives on page after clicking on filter (body has class 'filters-on')
body.filters-on, body.page-search, body.page-taxonomy-term-340 {
  #sidebar, #sidebar-bg {
    margin-left: -250px;
    
    @media only screen and (max-width: 767px) {
      margin-left: 0;
    }
    
    &.with-filters {
      margin-left: 0;
    }
  }  
  #block-block-9 {
    left: 0;

    &.active {
      left: -240px;
    }
    &:hover {
      background: white;  
    }
  }
}

#sidebar {
  display: block;
  @media only screen and (max-width: 767px) {
    //display: none;
  }
}

// Content

body.one-sidebar #content {
  @media only screen and (max-width: 767px) {
    padding-top: 53px
  }
}

body.filter_region #content {
  padding-top: 55px;
  @media only screen and (max-width: 767px) {
    padding-top: 96px;
  }
}

#content {
  padding: 20px 0 110px 260px;
  width: 100%;
  float: left;
  
  @media only screen and (max-width: 767px) {
    @include zen-grid-item(24,1);
    clear: left;
  }
  position: relative !important;
  @include transition(
    padding 0.2s ease-in-out,
    margin 0.2s ease-in-out
  );
  
  &.has-filters, &.has-categories {
    @media only screen and (max-width: 767px) {
      margin-left: 240px;
      
      #content-header {
        left: -240px;
      }
      
    }
  }
}

body.page-node #content {
  //padding: 20px 0 20px 260px;
}

body.not-front #content {
  //padding-right: 0;
  @media only screen and (max-width: 767px) {
    //padding-left: 0;
  }
}

body.page-cart, body.page-checkout {
  #content {
    min-height: 300px;
    padding: 20px 10px 20px;
  }
  #block-system-main {
    margin-right: 0;
  }
}



//
// Footer
//


#footer {
  position: relative;
}


#block-menu-block-3 { // Footer Menu Block
  @media only screen and (max-width: 767px) {
    display: none;
  }
  ul { 
    padding: 0;
    text-align: center;
    li { display: inline; }
  }
}


//
// Page Bottom
//

#block-search-form--2 { // Search Block in Footer (provided via context)
  display: none;
  background: $orange;
  padding: 10px 10px 0;
  form {
    display: inline-block;
    width: 100%;
  }
  input {
    min-height: 30px;
  }
  .form-item {
    width: 75%;
    display: block;
    float: left;
    margin: 0;
    input {
      width: 100%;
    }
  }
  .form-actions {
    width: 25%;
    display: block;
    float: right;
    input {
      width: 100%;
      opacity: .999;
      font-size: 1.3em;
      &:hover {
        background: lighten($red-dark, 15%);;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

//#block-menu-block-1--2
#block-menu-block-4 { // Main Menu Block in Footer
  @media only screen and (max-width: 767px) {
    display: inline-block !important;
  }
}
